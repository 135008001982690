import { commonImplementationHelper } from './commonImplementationHelper';

const hierarchy = {
  $options: {
    _componentTag: 'testComponent',
    parent: {
      $options: {
        _componentTag: 'testComponentParent',
      },
    },
  },
};

const mockSaveResponse = jest.fn();
const mockPayload = jest.fn();

jest.mock('./composables/PaymentMethodsHubComposable', () => ({
  PaymentMethodsHubComposable: jest.fn().mockReturnValue({
    getProfileInfo: () => ({
      isBusinessProfile: jest.fn(),
    }),
  }),
}));

jest.mock('vuex-composition-helpers/dist', () => ({
  useStore: () => ({
    getters: {
      'UserData/getUserData': {
        company: {
          uuid: 'test',
        },
        roles: 'default',
      },
      'CSOperator/getCurrentCSOperator': {},
      'Profile/getCurrentProfile': {},
      'Profile/getProfileCollection': [],
    },
    dispatch: (store, payload) => {
      mockSaveResponse(store);
      mockPayload(payload);
    },
  }),
}));

describe('Given commonImplementationHelper', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('When "onAddPaymentAction" is called', () => {
    describe('And the action is "authorised"', () => {
      it('Then the response is saved as "authorised"', () => {
        const { onAddPaymentAction } = commonImplementationHelper();

        onAddPaymentAction({ response: 'authorised' }, 'authorised', hierarchy);

        expect(mockSaveResponse).toHaveBeenCalled();
        expect(mockPayload).toHaveBeenCalledWith(
          expect.objectContaining({
            response: 'authorised',
          }),
        );
      });
    });

    describe('And the action is "refused"', () => {
      it('Then the response is saved as "refused"', () => {
        const { onAddPaymentAction } = commonImplementationHelper();

        onAddPaymentAction({ response: 'refused' }, 'refused', hierarchy);

        expect(mockSaveResponse).toHaveBeenCalled();
        expect(mockPayload).toHaveBeenCalledWith(
          expect.objectContaining({
            response: 'refused',
          }),
        );
      });
    });
  });
});
